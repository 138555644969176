import * as React from 'react';
import { createStyles, Input, InputProps } from '@mantine/core';

const useStyles = createStyles(() => ({
  wrapper: {
    marginRight: 8,
    marginLeft: 8,
    maxWidth: 180,
    display: 'inline-block',
  },
  input: {
    width: '100%',
    backgroundColor: 'rgba(0,0,0,0.25)',
    border: 0,
    '&:focus': {
      border: '1px red solid',
    },
    '&:hover': {
      border: '1px red solid',
    },
  },
}));

export default function InlineInput({
  ...others
}: InputProps & React.HTMLProps<HTMLInputElement>) {
  const { classes } = useStyles();
  return <Input classNames={classes} {...(others as InputProps)}></Input>;
}

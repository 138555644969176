import * as React from 'react';
import { Dispatch, SetStateAction, useState } from 'react';
import { ActionIcon, Chip, TextInput } from '@mantine/core';
import { IconPlus } from '@tabler/icons';

// shuffled suggestionsv
const attributeSuggestionsShuffled = [
  'crazy eyes',
  'space helmet',
  'spacesuit',
  'beard',
  'big smile',
  'cool visor',
  'glowing eyes',
  'face mask',
  'long hair',
  'glowing nose',
  'elf ears',
]
  .map((value) => ({ value, sort: Math.random() }))
  .sort((a, b) => a.sort - b.sort)
  .map(({ value }) => value);

export default function DreamerAttributesChips({
  onChange,
  dreamerAttributes,
  maxSelectedValues,
}: {
  onChange: Dispatch<SetStateAction<string[]>>;
  dreamerAttributes: string[];
  maxSelectedValues: number;
}) {
  const [attributeSuggestions, setAttributeSuggestions] = useState([
    ...attributeSuggestionsShuffled,
  ]);
  const [customValue, setCustomValue] = useState('');

  function addCustomProperty() {
    setAttributeSuggestions([...attributeSuggestions, customValue]);
    setCustomValue('');
  }

  return (
    <>
      <Chip.Group
        value={dreamerAttributes}
        onChange={(attributes) => {
          if (attributes.length >= maxSelectedValues + 1) {
            attributes.shift();
            onChange(attributes);
          } else {
            onChange(attributes);
          }
        }}
        multiple
      >
        {attributeSuggestions.map((dreamerAttribute) => {
          return (
            <Chip key={`attrib-${dreamerAttribute}`} value={dreamerAttribute}>
              {dreamerAttribute}
            </Chip>
          );
        })}
      </Chip.Group>
      <TextInput
        label="Add custom property:"
        placeholder="e.g steely gaze"
        variant="default"
        sx={{ maxWidth: 260 }}
        value={customValue}
        onChange={(event) => setCustomValue(event.currentTarget.value)}
        onKeyPress={(e) => {
          if (e.key === 'Enter') {
            addCustomProperty();
          }
        }}
        mt="md"
        rightSection={
          <ActionIcon
            size={32}
            variant="outline"
            color="red"
            onClick={addCustomProperty}
          >
            <IconPlus size={16} stroke={1.5} />
          </ActionIcon>
        }
      />
    </>
  );
}

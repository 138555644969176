import * as React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { App } from './App';
import reportWebVitals from './reportWebVitals';
import {
  Mainnet,
  DAppProvider,
  Config,
  Avalanche,
  Hardhat,
  Localhost,
} from '@usedapp/core';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Mint from './components/Mint';
import LandingPage from './components/LandingPage';
import DreamerDetails from './components/DreamerDetails';

const config: Config = {
  readOnlyChainId: Mainnet.chainId,
  readOnlyUrls: {
    [Mainnet.chainId]:
      'https://mainnet.infura.io/v3/2d4479662e3b4863b3fc5dba3889db94',
    [Avalanche.chainId]: 'https://api.avax.network/ext/bc/C/rpc',
    [Hardhat.chainId]: 'http://localhost:8545',
  },
  multicallAddresses: {
    [Hardhat.chainId]: '0xeefba1e63905ef1d7acba5a8513c70307c1ce441',
    [Localhost.chainId]: '0xeefba1e63905ef1d7acba5a8513c70307c1ce441',
  },
};

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <DAppProvider config={config}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<App />}>
            <Route index element={<LandingPage />} />
            <Route path="/mint/:collectionId" element={<Mint />} />
            <Route path="/:collectionId/:uid" element={<DreamerDetails />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </DAppProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import {
  Ether,
  Hardhat,
  Mainnet,
  NativeCurrency,
  useEthers,
} from '@usedapp/core';
import { ContractAddresses } from '../../src/shared/addresses/addresses';

export const nativeCurrencies = {
  [Mainnet.chainId]: Ether,
  [Hardhat.chainId]: new NativeCurrency('Ether', 'ETH', Hardhat.chainId),
};

export function useNativeCurrency() {
  const { chainId } = useEthers();
  return nativeCurrencies[
    chainId && chainId in nativeCurrencies ? chainId : Mainnet.chainId
  ];
}

export function useAddresses() {
  const { chainId } = useEthers();
  return chainId && chainId in ContractAddresses
    ? ContractAddresses[chainId as keyof typeof ContractAddresses]
    : // TODO: replace with mainnet
    ContractAddresses[31337];
}

import { BigNumber } from 'ethers';
import { DsgMinter } from './types';
import { verifyTypedData } from 'ethers/lib/utils';
// import { ContractAddresses } from "./addresses/addresses";
// import CoingGecko from "coingecko-api";

export type MintPayload<MintQuery> = {
  chainId: number;
  code: string;
  query: MintQuery;
};

export type MintData = {
  minter: string;
  price: BigNumber;
  timeout: BigNumber;
  uid: string;
};

export type SerializedMintData = {
  minter: string;
  price: string;
  timeout: string;
  uid: string;
};

export type GeneratePayload<MintQuery> = {
  uid: string;
  timeout: number;
  query: MintQuery;
  chainId: number;
  collectionId: string;
};

export type DsgType =
  | 'female'
  | 'male'
  | 'robot'
  | 'alien'
  | 'pirate'
  | 'explorer'
  | 'pilot'
  | 'diva'
  | 'hero'
  | 'villain';

export type DsgQuery = {
  adjective: string;
  types: DsgType[];
  attributes: string[];
};

export type GeneratedMetadata<MintQuery> = GeneratePayload<MintQuery> & {
  image: string;
  colony:
    | 'mercury'
    | 'earth'
    | 'mars'
    | 'venus'
    | 'neptune'
    | 'uranus'
    | 'saturn'
    | 'jupiter'
    | 'pluto';
  generationTstamp: number;
};

export type AuthMintResponse = {
  serializedMintData: SerializedMintData;
  mintSig: string;
  serializedGeneratePayload: string;
  genSig: string;
};

export type DeserializedAuthMintResponse<MintQuery> = {
  mintData: MintData;
  mintSig: string;
  serializedGeneratePayload: string;
  generatePayload: GeneratePayload<MintQuery>;
  genSig: string;
};

export type Domain = {
  name: string;
  version: string;
  chainId: number;
  verifyingContract: string;
};

export type DomainsJson = Record<number, Record<string, Domain>>;

export function serializeMintData(data: MintData): SerializedMintData {
  return {
    ...data,
    ...{ price: data.price.toHexString(), timeout: data.timeout.toHexString() },
  };
}

export function rehydrateMintData(data: SerializedMintData): MintData {
  return {
    ...data,
    ...{
      price: BigNumber.from(data.price),
      timeout: BigNumber.from(data.timeout),
    },
  };
}

export async function getDomain(
  mintContract: DsgMinter,
  chainId: number
): Promise<Domain> {
  const minterName = await mintContract.minterName();
  const minterVersion = await mintContract.minterVersion();

  return {
    name: minterName,
    version: minterVersion,
    chainId: chainId as number,
    verifyingContract: mintContract.address,
  };
}

export const signedTypes = {
  MintData: [
    {
      name: 'minter',
      type: 'address',
    },
    {
      name: 'price',
      type: 'uint256',
    },
    {
      name: 'timeout',
      type: 'uint256',
    },
    {
      name: 'uid',
      type: 'string',
    },
  ],
};

export async function recoverSignerFromMintData(
  mintData: MintData,
  signature: string,
  mintContract: DsgMinter,
  chainId: number
) {
  const domain = await getDomain(mintContract, chainId);
  return verifyTypedData(domain, signedTypes, mintData, signature);
}

// export const coingeckoNativeKey: Record<number, string> = {
//     1: 'ethereum',
//     43114: 'avalanche-2',
//     31337: 'ethereum'
// }

// // minter name => code => price
// export const targetUsd = {
//     DsgMinter: {
//         '': 85
//     }
// };

// const currencyPrices: any = await (new CoingGecko())
//     .simple
//     .price({ ids: Object.values(coingeckoNativeKey), vs_currencies: ['USD'] });

//     // const ourPrice: number = currencyPrices.data[currencyKey].usd;
//     // const mintPrice = ethers.utils.parseEther((Math.round(100 * 85 / ourPrice) / 100).toString());

// export function calcCodePrices() {
//     return Object.fromEntries(
//         Object.entries(coingeckoNativeKey)
//             .map(([chainId, gcKey]) => [chainId, ])
//     )
// }

export type Collection = {
  codePrices: Record<number, Record<string, number>>;
  prompts: {
    chosenType: string;
    attributes: string;
    adjective: string;
  };
  mintContract: string;
  timeFrameSeconds: number;
};

function standardPrompt(typeName: string) {
  return {
    chosenType: `${typeName}, who is a:`,
    attributes: 'With these attributes:',
    adjective: `I'm a`,
  };
}

export const collections: Record<string, Collection> = {
  dreamer: {
    codePrices: {
      1: {
        '': 0.05,
      },
      43114: {
        '': 4.25,
      },
      31337: {
        '': 0.05,
      },
    },
    mintContract: 'DsgMinter',
    prompts: standardPrompt('dreamer'),
    timeFrameSeconds: 15 * 60,
  },
  'space-pet': {
    codePrices: {
      1: {
        '': 0,
      },
      43114: {
        '': 0,
      },
      31337: {
        '': 0,
      },
    },
    mintContract: 'DspMinter',
    prompts: standardPrompt('space pet'),
    timeFrameSeconds: 15 * 60,
  },
};

export const rpcUrls: Record<number, string> = {
  1: 'https://mainnet.infura.io/v3/2d4479662e3b4863b3fc5dba3889db94',
  43114: 'https://api.avax.network/ext/bc/C/rpc',
  31337: 'http://localhost:8545',
};

import { Signer } from 'ethers';
import {
  DeserializedAuthMintResponse,
  AuthMintResponse,
  MintPayload,
  rehydrateMintData,
} from '../../src/shared';

export async function authorizeMint<MQ>(
  collection: string,
  payload: MintPayload<MQ>,
  signer: Signer
): Promise<DeserializedAuthMintResponse<MQ>> {
  const url = new URL(
    `/api/auth-mint/${collection}`,
    process.env.REACT_APP_API_SERVER ?? window.location.origin
  );
  const payloadString = encodeURIComponent(JSON.stringify(payload));
  const signature = await signer.signMessage(payloadString);
  url.searchParams.set('payload', payloadString);
  url.searchParams.set('signature', encodeURIComponent(signature));
  url.searchParams.set('code', payload.code);

  const response = await fetch(url);
  if (response.status === 200) {
    const amrs = await response.json<AuthMintResponse>();
    return {
      ...amrs,
      mintData: rehydrateMintData(amrs.serializedMintData),
      generatePayload: JSON.parse(
        decodeURIComponent(amrs.serializedGeneratePayload)
      ),
    };
  } else {
    throw new Error(
      `Mint wasn't authorized: ${
        (await response.json<{ error: string }>()).error
      }`
    );
  }
}

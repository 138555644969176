import * as React from 'react';
import { useForm } from '@mantine/form';
import {
  Button,
  Center,
  createStyles,
  Group,
  Stepper,
  Image,
  Loader,
} from '@mantine/core';
import { EnsureWalletConnected } from './account/EnsureWalletConnected';
import { useState } from 'react';
import DreamerTypeChips from './DreamerTypeChips';
import DreamerAttributesChips from './DreamerAttributesChips';
import { Stack, Container, Text } from '@mantine/core';
import PreviousNftsCarousel from '../components/PreviousNftsCarousel';
import {
  Avalanche,
  ChainId,
  CurrencyValue,
  Hardhat,
  Mainnet,
  NativeCurrency,
  NATIVE_CURRENCY,
  useEthers,
} from '@usedapp/core';
import {
  collections,
  DeserializedAuthMintResponse,
  DsgQuery,
  DsgType,
  MintPayload,
} from '../shared';
import { authorizeMint } from '../apis/auth-mint';
import { generateImage } from '../apis/imgen';
import { useMintTransaction } from '../chain/transactions';
import { BigNumber } from 'ethers';
import { useCollectionId } from '../utils';
import InlineInput from './InlineInput';
import TimeoutRing from './TimeoutRing';
import { parseEther } from 'ethers/lib/utils';
import { useNativeCurrency } from '../chain';

const useStyles = createStyles(() => ({
  stepIcon: {
    variant: 'outline',
    borderColor: 'gray',
    backgroundColor: 'rgba(0,0,0,0.075)',
    '&[data-completed]': {
      borderColor: 'teal',
      backgroundColor: 'rgba(0,0,0,0.075)',
      color: 'teal',
    },
    '&[data-progress]': {
      borderColor: 'red',
      backgroundColor: 'rgba(0,0,0,0.075)',
    },
    dropdown: {
      backgroundColor: 'rgba(0,0,0,0.25)',
    },
  },
  placeholder: {
    // opacity: 0.3,
    backgroundColor: 'rgba(0,0,0,0.15)',
  },
}));

export default function Mint() {
  const collectionId = useCollectionId();
  const { mintContract, prompts, timeFrameSeconds, codePrices } =
    collections[collectionId ?? 'dreamer'];
  const [chosenAttributes, setChosenAttributes] = useState<string[]>([]);
  const [chosenType, setChosenType] = useState<DsgType[]>([]);
  const [adjective, setAdjective] = useState<string>('beautiful');
  const [dreamerImage, setDreamerImage] = useState<string | undefined>(
    undefined
  );
  const [authMintResponse, setAuthMintResponse] = useState<
    DeserializedAuthMintResponse<DsgQuery> | undefined
  >(undefined);
  const [active, setActive] = useState(0);
  const code = '';

  const form = useForm({
    initialValues: {
      chosenType: [],
      attributes: [],
      name: '',
    },
    validate: () => {
      if (active === 0) {
        return {
          chosenType:
            chosenType.length < 1
              ? 'You must select at least one dreamer type'
              : null,
          attributes:
            chosenAttributes.length < 1
              ? 'You must choose at least one attribute'
              : null,
        };
      }

      if (active === 1) {
        return {};
      }

      return {};
    },
  });

  const nextStep = () =>
    setActive((current) => {
      if (form.validate().hasErrors) {
        return current;
      }
      return current < 3 ? current + 1 : current;
    });

  const prevStep = () =>
    setActive((current) => (current > 0 ? current - 1 : current));

  const { classes } = useStyles();

  const { chainId, library } = useEthers();
  const nativeCurrency = useNativeCurrency();
  const generate = async () => {
    const payload: MintPayload<DsgQuery> = {
      chainId: chainId!,
      code,
      query: {
        adjective,
        types: chosenType,
        attributes: chosenAttributes,
      },
    };

    if (library && chainId && collectionId) {
      setDreamerImage(undefined);
      const damrs = await authorizeMint(
        collectionId,
        payload,
        library.getSigner()
      );

      setAuthMintResponse(damrs);

      const img = await generateImage(damrs);
      setDreamerImage(img);
      console.log('%c ', `font-size:400px; background:url(${img}) no-repeat;`);
    }
  };

  const { send } = useMintTransaction(mintContract);

  const nc: Record<number, NativeCurrency> = {
    [Hardhat.chainId]: NATIVE_CURRENCY[Mainnet.chainId],
    [Avalanche.chainId]: new NativeCurrency(
      Avalanche.nativeCurrency!.name,
      Avalanche.nativeCurrency!.symbol,
      Avalanche.chainId
    ),
    ...NATIVE_CURRENCY,
  };

  function formatCodePrice(chainId: ChainId) {
    return new CurrencyValue(
      nc[chainId],
      parseEther(codePrices[chainId][code].toString())
    ).format();
  }

  return (
    <Stack
      sx={{
        width: '80%',
        minWidth: 520,
      }}
    >
      {authMintResponse ? (
        <TimeoutRing
          timeFrameSeconds={timeFrameSeconds}
          timeout={authMintResponse.mintData.timeout.toNumber()}
        ></TimeoutRing>
      ) : undefined}

      <Center sx={{ width: '100%' }}>
        <Container sx={{ width: '100%' }}>
          <Stack sx={{ width: '100%' }}>
            <Center>
              <h1>Dreamer Space Guild</h1>
            </Center>
            <Center>
              <p>Join the dreamy, space-bound future</p>
            </Center>
            <Center>
              <p>
                {Object.keys(codePrices)
                  .filter((k) => k !== '31337')
                  .map((key) => formatCodePrice(parseInt(key) as ChainId))
                  .join(' | ')}
              </p>
            </Center>

            <Container
              sx={{
                width: '80%',
                padding: 30,
              }}
              mx="auto"
              mt={'xl'}
            >
              <Center>
                <Stepper classNames={classes} active={active} breakpoint="sm">
                  <Stepper.Step label="Define" description="Dreamer">
                    <Stack mx="auto" mt="xl">
                      <Container>
                        <Text>
                          {prompts.adjective}
                          <InlineInput
                            value={adjective}
                            onChange={(event: any) =>
                              setAdjective(event.currentTarget.value)
                            }
                          ></InlineInput>
                          {prompts.chosenType}
                        </Text>
                        <br />
                        <DreamerTypeChips
                          onChange={setChosenType}
                          dreamerType={chosenType}
                          maxSelectedValues={3}
                        />
                      </Container>

                      <Container>
                        <Text>{prompts.attributes}</Text>
                        <br />
                        <DreamerAttributesChips
                          onChange={setChosenAttributes}
                          dreamerAttributes={chosenAttributes}
                          maxSelectedValues={5}
                        />
                      </Container>

                      <Group position="center" mt="xl">
                        <EnsureWalletConnected goal="Generate image">
                          <Button
                            variant="outline"
                            onClick={async () => {
                              nextStep();
                              await generate();
                            }}
                          >
                            Generate image
                          </Button>
                        </EnsureWalletConnected>
                      </Group>
                    </Stack>
                  </Stepper.Step>

                  <Stepper.Step label="Review" description="Image">
                    <Center>
                      <Image
                        classNames={classes}
                        styles={() => ({
                          image: {
                            visibility: dreamerImage ? 'visible' : 'hidden',
                          },
                        })}
                        radius="md"
                        width={512}
                        height={512}
                        src={dreamerImage}
                        withPlaceholder
                        placeholder={<Loader size="xl"></Loader>}
                      ></Image>
                    </Center>

                    <Group position="center" mt="xl">
                      <Button variant="outline" color="teal" onClick={prevStep}>
                        Change description
                      </Button>

                      <Button variant="outline" color="blue" onClick={generate}>
                        Try regenerating
                      </Button>

                      <Button variant="outline" onClick={nextStep}>
                        Choose image
                      </Button>
                    </Group>
                  </Stepper.Step>

                  <Stepper.Step label="Mint" description="NFT">
                    <Stack>
                      <EnsureWalletConnected goal="mint">
                        <Center>
                          {authMintResponse
                            ? new CurrencyValue(
                              nativeCurrency,
                              BigNumber.from(authMintResponse!.mintData.price)
                            ).format()
                            : ''}
                        </Center>
                        <Button
                          variant="outline"
                          size="lg"
                          type="submit"
                          onClick={() =>
                            send(
                              authMintResponse!.mintData,
                              authMintResponse!.mintSig,
                              {
                                value: BigNumber.from(
                                  authMintResponse!.mintData.price
                                ),
                              }
                            )
                          }
                        >
                          Mint Dreamer Space Guild NFT
                        </Button>
                      </EnsureWalletConnected>
                      <Group position="center" mt="xl">
                        <Button
                          variant="outline"
                          color="teal"
                          onClick={prevStep}
                        >
                          Back
                        </Button>
                      </Group>
                    </Stack>
                  </Stepper.Step>
                </Stepper>
              </Center>
            </Container>
          </Stack>
        </Container>
      </Center>
      <Center sx={{ width: '100%' }}>
        <Container sx={{ width: '100%' }}>
          <Stack>
            <PreviousNftsCarousel />
          </Stack>
        </Container>
      </Center>
    </Stack>
  );
}

import * as React from 'react';
import {
  createStyles,
  Image,
  Container,
  Title,
  Text,
  Chip,
  Group,
  Center,
} from '@mantine/core';
import { IconArrowBack } from '@tabler/icons';
import { useMatch, useNavigate } from 'react-router-dom';
import { ImageCheckbox } from './DreamerColony';
import { DsgQuery, GeneratedMetadata } from '../shared';
import { colony } from '../utils';

const useStyles = createStyles((theme) => ({
  wrapper: {
    paddingTop: theme.spacing.xl * 2,
    paddingBottom: theme.spacing.xl * 2,
  },

  details: {
    backgroundColor: 'rgba(0,0,0,0.25)',
    border: `1px solid ${theme.primaryColor}`,
    borderRadius: '8px',
    marginTop: '26px',
    marginBottom: '26px',
  },

  description: {
    textTransform: 'capitalize',
  },

  title: {
    marginBottom: theme.spacing.md,
    paddingLeft: theme.spacing.md,
    color: theme.colorScheme === 'dark' ? theme.white : theme.black,
  },

  item: {
    fontSize: theme.fontSizes.sm,
    borderRadius: '8px',
    padding: '16px',
    marginTop: '22px',
  },

  item_title: {
    fontWeight: 700,
    fontSize: '20px',
    marginBottom: '20px',
  },
}));

export default function DreamerDetails() {
  const { classes } = useStyles();
  const navigate = useNavigate();
  const match = useMatch('/:collectionId/:uid');
  const uid = match?.params.uid;
  const [details, setDetails] = React.useState<GeneratedMetadata<DsgQuery>>({
    chainId: 43114,
    uid: uid ?? '',
    collectionId: '0x000000',
    colony: 'mars',
    generationTstamp: 0,
    query: {
      adjective: '',
      attributes: [''],
      types: ['robot'],
    },
    image: '',
    timeout: 0,
  });

  React.useEffect(() => {
    fetch(`https://static.dreamerspaceguild.com/${details.uid}`)
      .then((response) => response.json())
      .then((res: any) => {
        setDetails({
          ...details,
          image: res.image,
          uid: res.uid,
          timeout: res.timeout,
          colony: res.colony,
          generationTstamp: res.generationTstamp,
          query: {
            adjective: res.query.adjective,
            types: res.query.types,
            attributes: res.query.attributes,
          },
        });
      });
  }, []);

  const selectedColony = colony[details.colony];

  return (
    <Container sx={{ maxWidth: 2000 }}>
      <Group onClick={() => navigate(-1)} style={{ cursor: 'pointer' }}>
        <IconArrowBack />
        <Text>Back</Text>
      </Group>
      <br />
      <Center>
        <Group grow align="stretch">
          <Container mt={0}>
            <Image src={details.image} alt="NFT Dreamer" radius={'md'} />
          </Container>

          <Container mt={0}>
            <Title order={2} align="left" className={classes.title}>
              Space Dreamer #{details.uid}
            </Title>

            <Container className={classes.item}>
              <Text className={classes.item_title} color="white">
                Description
              </Text>
              <Text color="white" className={classes.description}>
                {details.query.adjective} {details.query.attributes.join(' ')}{' '}
                {details.query.types.join(' ')}
              </Text>
            </Container>

            <Container className={classes.item}>
              <Text className={classes.item_title} color="white">
                Attributes
              </Text>
              <Chip.Group value={details.query.attributes}>
                {details.query.attributes.map((dreamerType) => {
                  return (
                    <Chip
                      key={`chip-${dreamerType}`}
                      variant="outline"
                      value={dreamerType}
                    >
                      {dreamerType}
                    </Chip>
                  );
                })}
              </Chip.Group>
            </Container>

            <Container className={classes.item}>
              <Text className={classes.item_title} color="white">
                Colony
              </Text>
              <ImageCheckbox
                checked={true}
                defaultChecked={true}
                title={selectedColony.title}
                description={selectedColony.description}
                image={selectedColony.image}
              />
            </Container>
          </Container>
        </Group>
      </Center>
    </Container>
  );
}

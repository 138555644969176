import * as React from 'react';
import {
  ActionIcon,
  Button,
  Center,
  Container,
  createStyles,
  Group,
  Text,
} from '@mantine/core';
import { useNavigate } from 'react-router-dom';
import PreviousNftsCarousel from './PreviousNftsCarousel';
import { IconBrandTwitter, IconBrandDiscord } from '@tabler/icons';

const BREAKPOINT = '@media (max-width: 755px)';

const useStyles = createStyles((theme) => ({
  wrapper: {
    position: 'relative',
    boxSizing: 'border-box',
  },

  inner: {
    backgroundColor: 'transparent',
    position: 'relative',
    paddingTop: 100,
    paddingBottom: 100,
    textAlign: 'center',

    [BREAKPOINT]: {
      paddingBottom: 80,
      paddingTop: 80,
    },
  },

  title: {
    fontSize: 52,
    fontWeight: 900,
    lineHeight: 1.1,
    margin: 0,
    padding: 0,
    color: theme.colorScheme === 'dark' ? theme.white : theme.black,

    [BREAKPOINT]: {
      fontSize: 42,
      lineHeight: 1.2,
    },
  },

  description: {
    marginTop: theme.spacing.xl,
    fontSize: 24,

    [BREAKPOINT]: {
      fontSize: 18,
    },
  },

  controls: {
    marginTop: theme.spacing.xl * 2,
    alignContent: 'center',

    [BREAKPOINT]: {
      marginTop: theme.spacing.xl,
    },
  },

  control: {
    height: 54,
    paddingLeft: 38,
    paddingRight: 38,

    [BREAKPOINT]: {
      height: 54,
      paddingLeft: 18,
      paddingRight: 18,
      flex: 1,
    },
  },
}));

export default function LandingPage() {
  const { classes } = useStyles();
  const navigate = useNavigate();

  return (
    <Container
      sx={{
        padding: 30,
        width: '50%',
        minWidth: 520,
        margin: 10,
      }}
    >
      <Center>
        <Container size={700} className={classes.inner}>
          <h1 className={classes.title}>
            <Text
              component="span"
              variant="gradient"
              gradient={{ from: '#9d2c55', to: '#f1ef81' }}
              inherit
            >
              Dreamer Space Guild
            </Text>
          </h1>
          <Text className={classes.description} color="white">
            The most innovative NFT collection in the solar system.
            <br />
          </Text>
          <br />
          <Group spacing={16} position="center" noWrap>
            <ActionIcon
              size="xl"
              onClick={() => {
                window.open('https://twitter.com/DreamerGuildNFT', '_blank');
              }}
            >
              <IconBrandTwitter size={40} stroke={1.4} />
            </ActionIcon>
            <ActionIcon
              size="xl"
              onClick={() => {
                window.open('https://discord.gg/xyuzVfeqsa', '_blank');
              }}
            >
              <IconBrandDiscord size={40} stroke={1.4} />
            </ActionIcon>
          </Group>
          <br />
          <Button
            size="xl"
            className={classes.control}
            variant="outline"
            onClick={() => navigate('/mint/dreamer')}
          >
            Join the Space Guild
          </Button>
        </Container>
      </Center>
      <PreviousNftsCarousel />
    </Container>
  );
}

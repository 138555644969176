import { useContractFunction, useEthers } from "@usedapp/core";
import { Contract } from "ethers";
import { Interface } from "ethers/lib/utils";
import { useAddresses } from "../";
import DsgMinterAbi from '../../../src/shared/abi/contracts/DsgMinter.sol/DsgMinter.json';
import { DsgMinter } from "../../shared/types";

const minterface = new Interface(DsgMinterAbi.abi);

export function useMintTransaction(minterContract: string) {
  const { library } = useEthers();
  const addresses = useAddresses();
  const contract = new Contract(addresses[minterContract as keyof typeof addresses], minterface, library) as DsgMinter;
  const { state, send } = useContractFunction(contract, 'mint', { transactionName: 'mint' });

  return { state, send };
}
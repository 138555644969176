import { CurrencyValue, useEthers } from '@usedapp/core';
import WalletConnectProvider from '@walletconnect/web3-provider';
import { BigNumber, ethers } from 'ethers';
import { useParams } from 'react-router-dom';
import Web3Modal from 'web3modal';
import CoreLogo from '../assets/img/core.svg';
import earth from '../assets/planets/earth.svg';
import jupiter from '../assets/planets/jupiter.svg';
import mars from '../assets/planets/mars.svg';
import mercury from '../assets/planets/mercury.svg';
import moon from '../assets/planets/moon.svg';
import neptune from '../assets/planets/neptune.svg';
import pluto from '../assets/planets/pluto.svg';
import saturn from '../assets/planets/saturn.svg';
import uranus from '../assets/planets/uranus.svg';
import venus from '../assets/planets/venus.svg';

export const colony: Record<
  string,
  { description: string; title: string; image: string }
> = {
  earth: { description: 'Sun and sea', title: 'Earth', image: earth },
  jupiter: { description: 'Sightseeing', title: 'Jupiter', image: jupiter },
  mars: { description: 'Mountains', title: 'Mars', image: mars },
  mercury: { description: 'Snow and ice', title: 'Mercury', image: mercury },
  moon: { description: 'Rocks', title: 'Moon', image: moon },
  neptune: { description: 'Water', title: 'Neptune', image: neptune },
  pluto: { description: 'Rocks and ice', title: 'Pluto', image: pluto },
  saturn: { description: 'Hidrogen and Hot', title: 'Saturn', image: saturn },
  uranus: { description: 'Gas', title: 'Uranus', image: uranus },
  venus: { description: 'Carbon and acid', title: 'Venus', image: venus },
};

export function sqrt(value: BigNumber): BigNumber {
  const ONE = ethers.BigNumber.from(1);
  const TWO = ethers.BigNumber.from(2);
  const x = ethers.BigNumber.from(value);
  let z = x.add(ONE).div(TWO);
  let y = x;
  while (z.sub(y).isNegative()) {
    y = z;
    z = x.div(z).add(z).div(TWO);
  }
  return y;
}

export function parseFloatNoNaN(input: string) {
  const parsed = parseFloat(input);
  return isNaN(parsed) ? 0 : parsed;
}
export function parseFloatCurrencyValue(input: CurrencyValue) {
  const parsed = parseFloatNoNaN(
    input.format({
      significantDigits: Infinity,
      suffix: '',
      thousandSeparator: '',
      decimalSeparator: '.',
    })
  );
  return isNaN(parsed) ? 0 : parsed;
}
export function formatNumber(input: number) {
  if (input) {
    return input.toLocaleString('en-US', {});
  } else {
    return (0).toLocaleString('en-US', {});
  }
}

export function useConnectWallet() {
  const { activate } = useEthers();
  const providerOptions = {
    injected:
      typeof window?.ethereum?.isAvalanche !== 'undefined'
        ? {
          display: {
            logo: CoreLogo,
            name: 'Core Wallet',
          },
          package: 'metamask',
        }
        : {
          package: 'metamask',
        },
    walletconnect: {
      package: WalletConnectProvider,
      options: {
        infuraId: '27e484dcd9e3efcfd25a83a78777cdf1', // required
      },
    },
  };
  async function onConnect() {
    try {
      const web3Modal = new Web3Modal({
        providerOptions, // required
        theme: 'dark',
      });

      const provider = await web3Modal.connect();
      await activate(provider);
    } catch (error) {
      console.error(error);
    }
  }
  return { onConnect };
}

export function useCollectionId() {
  const { collectionId } = useParams<'collectionId'>();
  return collectionId;
}

export function useUid() {
  const { uid } = useParams<'uid'>();
  return uid;
}

import { Button } from '@mantine/core';
import * as React from 'react';
import { useContext } from 'react';
import { UserAddressContext } from '../../contexts/UserAddressContext';
import { useConnectWallet } from '../../utils';

export function EnsureWalletConnected(
  params: React.PropsWithChildren<{ goal: string }>
) {
  const { onConnect } = useConnectWallet();
  const account = useContext(UserAddressContext);

  return (
    <>
      {account ? (
        params.children
      ) : (
        <Button
          variant="outline"
          onClick={() => {
            onConnect();
          }}
        >
          Connect wallet to {params.goal}
        </Button>
      )}
    </>
  );
}

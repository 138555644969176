import * as React from 'react';
import { Center, RingProgress, Stack, Text } from '@mantine/core';
import { useInterval } from '@mantine/hooks';

export default function TimeoutRing({
  timeout,
  timeFrameSeconds,
}: {
  timeout: number | undefined;
  timeFrameSeconds: number;
}) {
  // adjusted to allow for transaction to clear
  const timeFrame = timeFrameSeconds - 60 * 5;
  const adjustedTimeout = timeout ? timeout - 60 * 5 : undefined;
  const [secondsRemaining, setSecondsRemaining] = React.useState(
    Math.max(
      0,
      adjustedTimeout ? adjustedTimeout - Date.now() / 1000 : timeFrame
    )
  );
  const interval = useInterval(
    () =>
      setSecondsRemaining(
        Math.max(
          0,
          adjustedTimeout ? adjustedTimeout - Date.now() / 1000 : timeFrame
        )
      ),
    1000
  );
  React.useEffect(() => {
    if (
      adjustedTimeout &&
      adjustedTimeout >= Date.now() / 1000 &&
      !interval.active
    ) {
      interval.start();
    } else if (
      (!adjustedTimeout || Date.now() / 1000 > adjustedTimeout) &&
      interval.active
    ) {
      interval.stop();
    }
  }, [adjustedTimeout, secondsRemaining]);

  const percentRemaining = (100 * secondsRemaining) / timeFrame;
  const progressColor =
    percentRemaining > 50 ? 'teal' : percentRemaining > 25 ? 'blue' : 'red';
  return (
    <Stack
      sx={{
        position: 'absolute',
        backgroundColor: 'rgba(0,0,0,0.16)',
        borderRadius: 15,
        padding: 30,
        margin: 10,
        width: 240,
        height: 240,
      }}
    >
      <Text align="center">Time to mint</Text>
      <Center>
        <RingProgress
          roundCaps={true}
          thickness={20}
          size={160}
          sections={[
            { value: 100 - percentRemaining, color: progressColor },
            { value: percentRemaining, color: 'rgba(0,0,0,0.25' },
          ]}
          label={
            <Text color={progressColor} align="center" size="xl">
              {`${Math.floor(secondsRemaining / 60)}:${Math.floor(
                secondsRemaining % 60
              ).toLocaleString(undefined, { minimumIntegerDigits: 2 })}`}
            </Text>
          }
        ></RingProgress>
      </Center>
    </Stack>
  );
}

import * as React from 'react';
import { Dispatch, SetStateAction } from 'react';
import { Chip } from '@mantine/core';
import { DsgType } from '../shared';

export default function DreamerTypeChips({
  onChange,
  dreamerType,
  maxSelectedValues,
}: {
  onChange: Dispatch<SetStateAction<DsgType[]>>;
  dreamerType: DsgType[];
  maxSelectedValues: number;
}) {
  const dreamerTypes: DsgType[] = [
    'female',
    'male',
    'robot',
    'alien',
    'pirate',
    'explorer',
    'pilot',
    'diva',
    'hero',
    'villain',
  ];

  return (
    <Chip.Group
      value={dreamerType}
      onChange={(attributes) => {
        if (attributes.length >= maxSelectedValues + 1) {
          attributes.shift();
          onChange(attributes as DsgType[]);
        } else {
          onChange(attributes as DsgType[]);
        }
      }}
      multiple
    >
      {dreamerTypes.map((dreamerType) => {
        return (
          <Chip
            key={`type-${dreamerType}`}
            variant="outline"
            value={dreamerType}
          >
            {dreamerType}
          </Chip>
        );
      })}
    </Chip.Group>
  );
}

import * as React from 'react';
import Autoplay from 'embla-carousel-autoplay';
import { Carousel } from '@mantine/carousel';
import image1 from '../assets/previous-nfts/image1.png';
import image2 from '../assets/previous-nfts/image2.png';
import image3 from '../assets/previous-nfts/image3.png';
import image4 from '../assets/previous-nfts/image4.png';
import image5 from '../assets/previous-nfts/image5.png';
import image6 from '../assets/previous-nfts/image6.png';
import image7 from '../assets/previous-nfts/image7.png';
import image8 from '../assets/previous-nfts/image8.png';
import image9 from '../assets/previous-nfts/image9.png';
import image10 from '../assets/previous-nfts/image10.png';
import image11 from '../assets/previous-nfts/image11.png';
import image12 from '../assets/previous-nfts/image12.png';
import image13 from '../assets/previous-nfts/image13.png';
import image14 from '../assets/previous-nfts/image14.png';
import image15 from '../assets/previous-nfts/image15.png';
import image16 from '../assets/previous-nfts/image16.png';
import image17 from '../assets/previous-nfts/image17.png';
import { createStyles } from '@mantine/core';
import { useNavigate } from 'react-router-dom';

const useStyles = createStyles(() => ({
  image: {
    borderRadius: `16px`,
  },
  root: {
    borderRadius: `16px`,
    width: '100%',
  },
}));

export default function PreviousNftsCarousel() {
  const autoplay = React.useRef(Autoplay({ delay: 2200 }));
  const images = [
    image1,
    image2,
    image3,
    image4,
    image5,
    image6,
    image7,
    image8,
    image9,
    image10,
    image11,
    image12,
    image13,
    image14,
    image15,
    image16,
    image17,
  ];
  const { classes } = useStyles();
  const navigate = useNavigate();

  return (
    <Carousel
      className={classes.root}
      plugins={[autoplay.current]}
      onMouseEnter={autoplay.current.stop}
      onMouseLeave={autoplay.current.reset}
      orientation="horizontal"
      withControls={false}
      slideSize="12.333333%"
      slideGap="lg"
      loop
    >
      {images.map((image, index) => {
        return (
          <Carousel.Slide
            key={`slide-${index}`}
            onClick={() => navigate(`/dreamer/${index}`)}
            style={{ cursor: 'pointer' }}
          >
            <img
              src={image}
              height="250px"
              width={'250px'}
              alt={`nft-${index}`}
              className={classes.image}
            />
          </Carousel.Slide>
        );
      })}
    </Carousel>
  );
}

import { DeserializedAuthMintResponse } from '../shared';

const imgenUrl = 'https://image-generator-o727bibr2q-uc.a.run.app';
// const imgenUrl = 'http://localhost:8080';
export async function generateImage<MQ>(
  damrs: DeserializedAuthMintResponse<MQ>
): Promise<string> {
  const url = new URL(imgenUrl);
  url.searchParams.set(
    'serializedGeneratePayload',
    damrs.serializedGeneratePayload
  );
  url.searchParams.set('genSig', damrs.genSig);

  const response = await fetch(url);

  if (response.status === 200) {
    const imageBlob = await response.blob();
    const imageObjectURL = URL.createObjectURL(imageBlob);
    return imageObjectURL;
  } else {
    throw new Error(`Generation failed: ${response.body}`);
  }
}

import * as React from 'react';
import {
  UnstyledButton,
  Checkbox,
  Text,
  Image,
  SimpleGrid,
  createStyles,
} from '@mantine/core';
import { useUncontrolled } from '@mantine/hooks';
import earth from '../assets/planets/earth.svg';
import jupiter from '../assets/planets/jupiter.svg';
import mars from '../assets/planets/mars.svg';
import mercury from '../assets/planets/mercury.svg';
import moon from '../assets/planets/moon.svg';
import neptune from '../assets/planets/neptune.svg';
import pluto from '../assets/planets/pluto.svg';
import saturn from '../assets/planets/saturn.svg';
import uranus from '../assets/planets/uranus.svg';
import venus from '../assets/planets/venus.svg';

const useStyles = createStyles((theme, { checked }: { checked: boolean }) => ({
  button: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    transition: 'background-color 150ms ease, border-color 150ms ease',
    border: `1px solid ${
      checked
        ? theme.fn.variant({ variant: 'outline', color: theme.primaryColor })
          .border
        : theme.colorScheme === 'dark'
          ? theme.colors.dark[8]
          : theme.colors.gray[3]
    }`,
    borderRadius: theme.radius.sm,
    padding: theme.spacing.sm,
    backgroundColor: 'transparent',
  },

  body: {
    flex: 1,
    marginLeft: theme.spacing.md,
  },
}));

interface ImageCheckboxProps {
  checked?: boolean;
  defaultChecked?: boolean;
  onChange?(checked: boolean): void;
  title: string;
  description: string;
  image: string;
}

export function ImageCheckbox({
  checked,
  defaultChecked,
  onChange,
  title,
  description,
  className,
  image,
  ...others
}: ImageCheckboxProps &
  Omit<React.ComponentPropsWithoutRef<'button'>, keyof ImageCheckboxProps>) {
  const [value, handleChange] = useUncontrolled({
    value: checked,
    defaultValue: defaultChecked,
    finalValue: false,
    onChange,
  });

  const { classes, cx } = useStyles({ checked: value });

  return (
    <UnstyledButton
      {...others}
      onClick={() => handleChange(!value)}
      className={cx(classes.button, className)}
    >
      <Image src={image} alt={title} width={40} />

      <div className={classes.body}>
        <Text color="white" size="xs" sx={{ lineHeight: 1 }} mb={5}>
          {description}
        </Text>
        <Text weight={500} color="white" size="sm" sx={{ lineHeight: 1 }}>
          {title}
        </Text>
      </div>

      <Checkbox
        checked={value}
        tabIndex={-1}
        styles={{ input: { cursor: 'pointer' } }}
      />
    </UnstyledButton>
  );
}

const mockdata = [
  { description: 'Sun and sea', title: 'Earth', image: earth },
  { description: 'Sightseeing', title: 'Jupiter', image: jupiter },
  { description: 'Mountains', title: 'Mars', image: mars },
  { description: 'Snow and ice', title: 'Mercury', image: mercury },
  { description: 'Rocks', title: 'Moon', image: moon },
  { description: 'Water', title: 'Neptune', image: neptune },
  { description: 'Rocks and ice', title: 'Pluto', image: pluto },
  { description: 'Hidrogen and Hot', title: 'Saturn', image: saturn },
  { description: 'Gas', title: 'Uranus', image: uranus },
  { description: 'Carbon and acid', title: 'Venus', image: venus },
];

export default function DreamerColony() {
  const items = mockdata.map((item) => (
    <ImageCheckbox {...item} key={item.title} />
  ));
  return (
    <SimpleGrid
      cols={2}
      breakpoints={[
        { maxWidth: 'md', cols: 2 },
        { maxWidth: 'sm', cols: 1 },
      ]}
    >
      {items}
    </SimpleGrid>
  );
}

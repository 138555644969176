import * as React from 'react';
import { AppShell, BackgroundImage, Center, MantineProvider } from '@mantine/core';
import { Outlet } from 'react-router-dom';
import { UserAddressCtxProvider } from './contexts/UserAddressContext';

export const App = (params: React.PropsWithChildren<unknown>) => {
  return (
    <MantineProvider
      theme={{
        colorScheme: 'dark',
        colors: {
          gray: [
            '#F4F1F3',
            '#DFD7DC',
            '#CBBEC6',
            '#B6A4B0',
            '#A28B9A',
            '#8E7184',
            '#715B69',
            '#55444F',
            '#392D35',
            '#1C171A',
          ],
          orange: [
            '#F9ECEC',
            '#EEC8C8',
            '#E3A5A5',
            '#D98282',
            '#CE5F5F',
            '#C33C3C',
            '#9C3030',
            '#752424',
            '#4E1818',
            '#270C0C',
          ],
          red: [
            '#FCE8E9',
            '#F7C0C1',
            '#F1979A',
            '#EC6F72',
            '#E7464A',
            '#E11E23',
            '#B4181C',
            '#871215',
            '#5A0C0E',
            '#2D0607',
          ],
          green: [
            '#EEF7F1',
            '#CFE7D7',
            '#B0D8BD',
            '#92C9A3',
            '#73BA89',
            '#54AB6F',
            '#438959',
            '#326743',
            '#22442C',
            '#112216',
          ],
          blue: [
            '#E5F8FF',
            '#B8EBFF',
            '#8ADDFF',
            '#5CD0FF',
            '#2EC3FF',
            '#00B6FF',
            '#0092CC',
            '#006D99',
            '#004966',
            '#002433',
          ],
          violet: [
            '#F0EEF7',
            '#D4CFE8',
            '#B9B0D9',
            '#9E91CA',
            '#8372BB',
            '#6753AC',
            '#53428A',
            '#3E3267',
            '#292145',
            '#151122',
          ],
          pink: [
            '#FFE9E5',
            '#FFC1B8',
            '#FF998A',
            '#FF715C',
            '#FF492E',
            '#FF2200',
            '#CC1B00',
            '#991400',
            '#660D00',
            '#330700',
          ],
          yellow: [
            '#FFF8E6',
            '#FEECB8',
            '#FEE08B',
            '#FED45D',
            '#FDC830',
            '#FDBC02',
            '#CA9602',
            '#987101',
            '#654B01',
            '#332600',
          ],
          teal: [
            '#E6FFFE',
            '#B8FFFE',
            '#8AFFFD',
            '#5CFEFC',
            '#2FFEFB',
            '#01FEFA',
            '#01CBC8',
            '#019896',
            '#006664',
            '#003332',
          ],
          grape: [
            '#FAEAED',
            '#F2C4CD',
            '#EA9FAE',
            '#E2798E',
            '#DA536E',
            '#D12E4E',
            '#A7253E',
            '#7E1B2F',
            '#54121F',
            '#2A0910',
          ],
        },
        white: '#fff6f2',
        black: '#0E0403',
        primaryColor: 'red',
        primaryShade: { light: 8, dark: 5 },
        defaultGradient: { deg: 45, from: 'red.2', to: 'pink.5' },
        fontFamily: 'Orbitron',
        headings: {
          fontFamily: 'Orbitron',
        },
        components: {
          TextInput: {
            styles: () => ({
              input: {
                radius: 'md',
                backgroundColor: 'rgba(0,0,0,0.25)',
                border: 0,
                '&:focus': {
                  border: '1px red solid'
                },
                '&:hover': {
                  border: '1px red solid'
                }
              }
            })
          },
          Container: {
            styles: () => ({
              root: {
                backgroundColor: 'rgba(0,0,0,0.16)',
                borderRadius: 15,
                padding: 30,
                margin: 10
              }
            })
          },
          Button: {
            styles: () => ({
              root: {
                '&': {
                  backgroundColor: 'rgba(0,0,0,0.1)',
                },
              },
            }),
          },
          Chip: {
            styles: () => ({
              label: {
                '&[data-variant="outline"]': {
                  '&': {
                    backgroundColor: 'rgba(0,0,0,0.3)',
                    border: 0
                  },
                },
              },
            }),
          },
        },
      }}
      withGlobalStyles
      withNormalizeCSS
    >
      <BackgroundImage src="../bigbg.png" sx={{ minHeight: '100vh', minWidth: '100vh' }}>
        <AppShell sx={{ width: '100%' }} padding="lg">
          <UserAddressCtxProvider>
            {params.children}
            <Center>
              <Outlet />
            </Center>
          </UserAddressCtxProvider>
        </AppShell>

      </BackgroundImage>
    </MantineProvider>
  );
};
